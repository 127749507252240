<template>
  <v-container fill-height>
    <v-layout justify-center>
      <v-flex xs12 sm12 md11 lg10 xl8>
        <v-form class="elevation-5">
          <template>
            <v-toolbar color="primary" dark dense>
              <v-icon class="mr-2">mdi-toy-brick-outline</v-icon>
              <v-toolbar-title>{{ tituloForm }}</v-toolbar-title>
              <v-icon class="ml-2" small>mdi-asterisk</v-icon>
              <v-spacer></v-spacer>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    class="mr-2"
                    v-bind="attrs"
                    v-on="on"
                    @click="metClear"
                  >
                    mdi-refresh
                  </v-icon>
                </template>
                <span>Refresh</span>
              </v-tooltip>

              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    class="mr-2"
                    v-bind="attrs"
                    v-on="on"
                    @click="metVoltar()"
                  >
                    mdi-arrow-left-circle-outline
                  </v-icon>
                </template>
                <span>Voltar</span>
              </v-tooltip>
            </v-toolbar>
          </template>
          <template>
            <v-container fluid>
              <v-row dense>
                <v-col cols="auto" md="2" align-self="center">
                  <v-text-field
                    hide-details="auto"
                    v-model="componenteLocal.id"
                    label="ID"
                    readonly
                    outlined
                  ></v-text-field>
                </v-col>
                <v-col md="10" align-self="center">
                  <v-text-field
                    hide-details="auto"
                    v-model="componenteLocal.componente"
                    :error-messages="compComponenteErrors"
                    label="Componente"
                    required
                    outlined
                    @input="$v.componenteLocal.componente.$touch()"
                    @blur="$v.componenteLocal.componente.$touch()"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="auto" md="12" align-self="center">
                  <v-text-field
                    hide-details="auto"
                    v-model="componenteLocal.campo"
                    :error-messages="compCampoErrors"
                    label="Campo"
                    required
                    outlined
                    @input="$v.componenteLocal.campo.$touch()"
                    @blur="$v.componenteLocal.campo.$touch()"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </template>
          <template>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  class="ml-4 mr-4 mb-4"
                  color="green darken-2"
                  v-bind="attrs"
                  v-on="on"
                  @click="metSubmit"
                >
                  mdi-check-circle
                </v-icon>
              </template>
              <span>Salvar</span>
            </v-tooltip>

            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  class="mr-4 mb-4"
                  color="red"
                  v-bind="attrs"
                  v-on="on"
                  @click="metVoltar()"
                >
                  mdi-backspace
                </v-icon>
              </template>
              <span>Cancelar</span>
            </v-tooltip>
          </template>
        </v-form>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";

const { mapState, mapActions } = createNamespacedHelpers("adminComponentes");

export default {
  mixins: [validationMixin],
  validations: {
    componenteLocal: {
      componente: { required },
      campo: { required },
    },
  },

  data: () => ({
    tituloForm: "",
    componenteLocal: {},
  }),
  computed: {
    ...mapState({ staComponenteSelecionado: "componenteSelecionado" }),

    compComponenteErrors() {
      const errors = [];
      if (!this.$v.componenteLocal.componente.$dirty) return errors;
      !this.$v.componenteLocal.componente.required &&
        errors.push("Nome requerida.");
      return errors;
    },
    compCampoErrors() {
      const errors = [];
      if (!this.$v.componenteLocal.campo.$dirty) return errors;
      !this.$v.componenteLocal.campo.required &&
        errors.push("Descricão requerida");
      return errors;
    },
  },
  watch: {
    staComponenteSelecionado(componenteNovo) {
      this.metSincronizar(componenteNovo);
    },
  },
  created() {
    this.metSincronizar(this.staComponenteSelecionado);
    this.staComponenteSelecionado
      ? (this.tituloForm = "Editar Componente")
      : (this.tituloForm = "Novo Componente");
  },
  methods: {
    ...mapActions({
      actCriarComponente: "criarComponente",
      actEditarComponente: "editarComponente",
    }),

    async metSalvarComponente() {
      this.componenteLocal.componente = this.componenteLocal.componente.trim();
      this.componenteLocal.campo = this.componenteLocal.campo.trim();

      switch (this.staComponenteSelecionado) {
        case undefined:
          this.actCriarComponente({ componente: this.componenteLocal });
          this.$root.snackbar.show({
            type: "success",
            message: "Componente Criado",
          });
          break;
        default:
          await this.actEditarComponente({ componente: this.componenteLocal });
          this.$root.snackbar.show({
            type: "success",
            message: "Componente Salvo",
          });
      }
    },

    metSincronizar(novoComponente) {
      this.componenteLocal = Object.assign({}, novoComponente || { id: null });
    },
    metSubmit() {
      this.$v.$touch();
      this.metSalvarComponente();
    },
    metClear() {
      this.componenteLocal.componente =
        this.staComponenteSelecionado.componente;
      this.componenteLocal.campo = this.staComponenteSelecionado.campo;
    },
    metVoltar() {
      this.$router.back();
    },
  },
};
</script>
